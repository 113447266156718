import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import AuthContext from "../../layouts/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../apiConfig";

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { user, checkAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      if (typeof checkAuth === 'function') {
        await checkAuth();
        if (user) {
          navigate("/admin/default");
        }
      } else {
        console.error('checkAuth is not a function');
      }
    };
    verifyAuth();
  }, [user, checkAuth, navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);

      const response = await axios.post(apiConfig.getToken, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const token = response.data.data.access_token;
      localStorage.setItem("access_token", token);

      // Проверка авторизации после получения токена
      if (typeof checkAuth === 'function') {
        await checkAuth();
        // Перенаправить пользователя на страницу Dashboard
        navigate("/admin/default");
      } else {
        console.error('checkAuth is not a function');
      }
    } catch (err) {
      setError(err.response ? err.response.data.message : "Something went wrong");
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <form onSubmit={handleSignIn}>
          {/* Username */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="john_doe"
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <p className="text-red-500 mb-3">{error}</p>}

          {/* Sign In Button */}
          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
}