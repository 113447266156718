import React, { useState, useEffect } from "react";
import axios from "axios";
import Checkbox from "../../../components/checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import apiConfig from "../../../apiConfig";
import Select from "react-select";

const EditBot = () => {
  const location = useLocation();
  const { botData: initialBotData } = location.state || {};
  const [botState, setBotState] = useState(initialBotData || {
    name: "",
    deposit: null,
    max_active_cycles: null,
    profit_capitalization: null,
    quote_asset: "",
    symbol_sort_type: "",
    calculation_mode: "",
    type: "",
    unique_symbol: false,
    black_symbols: [],
    white_symbols: {},
    indicators: [],
    base_asset: "",
  });
  const [settings, setSettings] = useState({
    first_order_offset: null,
    grid_length: null,
    log_coefficient: null,
    next_order_volume: null,
    num_orders: null,
    partial_num_orders: null,
    price_change_percentage: null,
    profit_percentage: null,
    upper_price_limit: null,
    trailing_delta: null,
    trailing_delta_offset: null,
    buy_trailing_delta: null,
    buy_trailing_delta_offset: null,
  });
  const [errors, setErrors] = useState({});
  const [newIndicator, setNewIndicator] = useState({
    indicator: "",
    interval: "",
    value: null,
    period: null,
    more_than: false,
    cross_type: null,
    cross_type_mode: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [selectedBlackAssets, setSelectedBlackAssets] = useState([]);
  const [selectedWhiteAssets, setSelectedWhiteAssets] = useState([]);
  const [selectedBaseAsset, setSelectedBaseAsset] = useState([]);

  const [availableAssets, setAvailableAssets] = useState([]);
  const [quote_asset, setQuote_asset] = useState('USDT');

  useEffect(() => {
    if (botState.white_symbols && Object.keys(botState.white_symbols).length > 0) {
      const firstSymbol = Object.keys(botState.white_symbols)[0];
      const initialSettings = botState.white_symbols[firstSymbol];
      setSettings({
        first_order_offset: initialSettings.first_order_offset,
        grid_length: initialSettings.grid_length,
        log_coefficient: initialSettings.log_coefficient,
        next_order_volume: initialSettings.next_order_volume,
        num_orders: initialSettings.num_orders,
        partial_num_orders: initialSettings.partial_num_orders,
        price_change_percentage: initialSettings.price_change_percentage,
        profit_percentage: initialSettings.profit_percentage,
        upper_price_limit: initialSettings.upper_price_limit,
        trailing_delta: initialSettings.trailing_delta,
        trailing_delta_offset: initialSettings.trailing_delta_offset,
        buy_trailing_delta: initialSettings.buy_trailing_delta,
        buy_trailing_delta_offset: initialSettings.buy_trailing_delta_offset,
      });
  
      if (botState.type === "SINGLE") {
        setBotState((prevData) => ({
          ...prevData,
          base_asset: firstSymbol,
        }));
      }
    }

    const fetchBaseAssets = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(apiConfig.getSymbols, {
          params: { quote_asset },
          headers: { Authorization: `Bearer ${token}` },
        });
        // Преобразуем данные в формат, который поддерживается react-select
        const assetsOptions = response.data.map((asset) => ({
          value: asset,
          label: asset,
        }));
        setAvailableAssets(assetsOptions);
      } catch (error) {
        console.error("Ошибка при загрузке списка монет:", error);
      }
    };

    fetchBaseAssets();
  }, [botState.white_symbols, botState.type, quote_asset]);
  
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "quote_asset") {
      setQuote_asset(value);
    }
  
    if (name in settings) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else {
      setBotState((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleIndicatorChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewIndicator((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const addIndicator = () => {
    setBotState((prevData) => ({
      ...prevData,
      indicators: [...prevData.indicators, newIndicator],
    }));
    setNewIndicator({
      indicator: "",
      interval: "",
      value: null,
      period: null,
      more_than: false,
      cross_type: null,
      cross_type_mode: null,
    });
  };

  const removeIndicator = (index) => {
    setBotState((prevData) => ({
      ...prevData,
      indicators: prevData.indicators.filter((_, i) => i !== index),
    }));
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true);

    let updatedBotData = {
      ...botState,
      white_symbols: botState.type === "SINGLE"
        ? [{ [botState.base_asset ? botState.base_asset.toUpperCase() : ""]: settings }]
        : Object.keys(botState.white_symbols).map(symbol => ({ [symbol]: settings })),
      max_active_cycles: botState.type === "SINGLE" ? 1 : botState.max_active_cycles,
    };

    // Remove unwanted fields
    const { base_asset, cycles, is_active, status, ...dataToSend } = updatedBotData;

    // Function to replace empty strings with null
    const replaceEmptyStringsWithNull = (obj) => {
        for (const key in obj) {
            if (obj[key] === '') {
                obj[key] = null;
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                replaceEmptyStringsWithNull(obj[key]);
            }
        }
    };

    // Replace empty strings with null
    replaceEmptyStringsWithNull(dataToSend);

    try {
        const token = localStorage.getItem("access_token");
        const response = await axios.post(apiConfig.editBot, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log("Bot updated successfully", response.data);
        navigate("/admin/default");
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            setErrors(error.response.data.errors);
        } else {
            console.error("Error updating bot", error);
        }
    } finally {
        setIsSubmitting(false);
    }
};

const handleBlackAssetsChange = (selectedOptions) => {
  const options = selectedOptions || [];
  const symbols = options.map(option => option.value);
  setSelectedBlackAssets(options);

  setBotState(prevState => ({
    ...prevState,
    black_symbols: symbols
  }));
};

const handleWhiteAssetsChange = (selectedOptions) => {
  const options = selectedOptions || [];
  const symbols = options.map(option => option.value);
  setSelectedWhiteAssets(options);

    setBotState(prevState => {
      const updatedWhiteSymbols = symbols.reduce((acc, symbol) => {
        acc[symbol] = prevState.white_symbols[symbol] || {
          grid_length: null,
          first_order_offset: null,
          log_coefficient: null,
          next_order_volume: null,
          num_orders: null,
          partial_num_orders: null,
          price_change_percentage: null,
          profit_percentage: null,
          upper_price_limit: null,
          trailing_delta: null,
          trailing_delta_offset: null,
          buy_trailing_delta: null,
          buy_trailing_delta_offset: null,
        };
        return acc;
      }, {});
      return {
        ...prevState,
        white_symbols: updatedWhiteSymbols
      };
    });

};
const handleBaseAssetChange = (selectedOptions) => {
  const options = selectedOptions || [];
  setSelectedBaseAsset(options);
  setBotState(prevState => ({
    ...prevState,
    base_asset: selectedOptions ? selectedOptions.value : ""
  }));
};
  

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4">
        {/* Bot Data Fields */}
        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
              type="text"
              name="name"
              value={botState.name || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
            Type
          </label>
          <select
              name="type"
              value={botState.type}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="SINGLE">SINGLE</option>
            <option value="MULTI">MULTI</option>
          </select>
          {errors.type && <p className="text-red-500 text-xs italic">{errors.type}</p>}
        </div>

        {botState.type === "MULTI" && (
            <>
              <div className="mb-4 col-span-2 sm:col-span-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="max_active_cycles">
                  Max Active Cycles
                </label>
                <input
                    type="number"
                    name="max_active_cycles"
                    value={botState.max_active_cycles || ""}
                    onChange={handleChange}
                    step="1"
                    min="1"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.max_active_cycles &&
                    <p className="text-red-500 text-xs italic">{errors.max_active_cycles}</p>}
              </div>

              <div className="mb-4 col-span-2 sm:col-span-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unique_symbol">
                  Unique Symbol
                </label>
                <Checkbox checked={botState.unique_symbol || false} onChange={handleChange} name="unique_symbol"/>
                {errors.unique_symbol &&
                    <p className="text-red-500 text-xs italic">{errors.unique_symbol}</p>}
              </div>

              <div className="mb-4 col-span-2 sm:col-span-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="symbol_sort_type">
                  Symbol Sort Type
                </label>
                <select
                    name="symbol_sort_type"
                    value={botState.symbol_sort_type || ""}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select sort mode</option>
                  <option value="VOLATILITY">VOLATILITY</option>
                  <option value="CAPITALIZATION">CAPITALIZATION</option>
                  <option value="VOLUME">VOLUME</option>
                  <option value="SEQUENCE">SEQUENCE</option>
                </select>
                {errors.symbol_sort_type &&
                    <p className="text-red-500 text-xs italic">{errors.symbol_sort_type}</p>}
              </div>

              <div className="mb-4 col-span-2 sm:col-span-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="black_symbols">
                  Black Symbols
                </label>
                <Select
                    isMulti
                    name="black_symbols"
                    options={availableAssets}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={botState.black_symbols.map(symbol => ({value: symbol, label: symbol}))}
                    onChange={handleBlackAssetsChange}
                    placeholder="Search and select assets..."
                />
                {errors.black_symbols &&
                    <p className="text-red-500 text-xs italic">{errors.black_symbols}</p>}
              </div>

              <div className="mb-4 col-span-2 sm:col-span-1">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="white_symbols">
                  White Symbols
                </label>
                <Select
                    isMulti
                    name="white_symbols"
                    options={availableAssets}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={Object.keys(botState.white_symbols).map(symbol => ({value: symbol, label: symbol}))}
                    onChange={handleWhiteAssetsChange}
                    placeholder="Search and select assets..."
                />
                {errors.white_symbols &&
                    <p className="text-red-500 text-xs italic">{errors.white_symbols}</p>}
              </div>
            </>
        )}

        {botState.type === "SINGLE" && (
            <div className="mb-4 col-span-2 sm:col-span-1">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="base_asset">
                Base Asset
              </label>
              <Select
                  name="base_asset"
                  options={availableAssets}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={botState.base_asset ? {value: botState.base_asset, label: botState.base_asset} : null}
                  onChange={handleBaseAssetChange}
                  placeholder="Search and select asset..."
              />
              {errors.base_asset && <p className="text-red-500 text-xs italic">{errors.base_asset}</p>}
            </div>
        )}

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quote_asset">
            Quote Asset
          </label>
          <select
              name="quote_asset"
              value={botState.quote_asset}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="USDT">USDT</option>
            <option value="FDUSD">FDUSD</option>
            <option value="USDC">USDC</option>
            <option value="BTC">BTC</option>
            <option value="ETH">ETH</option>
            <option value="BNB">BNB</option>
            <option value="TRY">TRY</option>
            <option value="EUR">EUR</option>
            <option value="BRL">BRL</option>
          </select>
          {errors.quote_asset && <p className="text-red-500 text-xs italic">{errors.quote_asset}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deposit">
            Deposit
          </label>
          <input
              type="number"
              name="deposit"
              value={botState.deposit || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.deposit && <p className="text-red-500 text-xs italic">{errors.deposit}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="grid_length">
            Grid Length
          </label>
          <input
              type="number"
              name="grid_length"
              value={settings.grid_length || ""}
              onChange={handleChange}
              min="1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.grid_length && <p className="text-red-500 text-xs italic">{errors.grid_length}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_order_offset">
            First Order Offset
          </label>
          <input
              type="number"
              name="first_order_offset"
              value={settings.first_order_offset || ""}
              onChange={handleChange}
              step="0.01"
              min="0"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.first_order_offset &&
              <p className="text-red-500 text-xs italic">{errors.first_order_offset}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="num_orders">
            Number of Orders
          </label>
          <input
              type="number"
              name="num_orders"
              value={settings.num_orders || ""}
              onChange={handleChange}
              min="1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.num_orders && <p className="text-red-500 text-xs italic">{errors.num_orders}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="partial_num_orders">
            Partial Number
          </label>
          <input
              type="number"
              name="partial_num_orders"
              value={settings.partial_num_orders || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.partial_num_orders &&
              <p className="text-red-500 text-xs italic">{errors.partial_num_orders}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="next_order_volume">
            Next Order Volume
          </label>
          <input
              type="number"
              name="next_order_volume"
              value={settings.next_order_volume || ""}
              onChange={handleChange}
              min="1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.next_order_volume &&
              <p className="text-red-500 text-xs italic">{errors.next_order_volume}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profit_percentage">
            Profit Percentage
          </label>
          <input
              type="number"
              name="profit_percentage"
              value={settings.profit_percentage}
              onChange={handleChange}
              step="0.01"
              min="0.1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.profit_percentage &&
              <p className="text-red-500 text-xs italic">{errors.profit_percentage}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="trailing_delta">
            Profit Trailing Delta
          </label>
          <input
              type="number"
              name="trailing_delta"
              value={settings.trailing_delta || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.trailing_delta &&
              <p className="text-red-500 text-xs italic">{errors.trailing_delta}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="trailing_delta_offset">
            Profit Trailing Delta Offset
          </label>
          <Checkbox checked={settings.trailing_delta_offset} onChange={handleChange} name="trailing_delta_offset"/>
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price_change_percentage">
            Price Change
          </label>
          <input
              type="number"
              name="price_change_percentage"
              value={settings.price_change_percentage || ""}
              onChange={handleChange}
              step="0.01"
              min="0.1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.price_change_percentage &&
              <p className="text-red-500 text-xs italic">{errors.price_change_percentage}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="log_coefficient">
            Log Coefficient
          </label>
          <input
              type="number"
              name="log_coefficient"
              value={settings.log_coefficient || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.log_coefficient && <p className="text-red-500 text-xs italic">{errors.log_coefficient}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buy_trailing_delta">
            Buy Trailing Delta
          </label>
          <input
              type="number"
              name="buy_trailing_delta"
              value={settings.buy_trailing_delta || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.buy_trailing_delta &&
              <p className="text-red-500 text-xs italic">{errors.buy_trailing_delta}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buy_trailing_delta_offset">
            Buy Trailing Delta Offset
          </label>
          <Checkbox checked={settings.buy_trailing_delta_offset} onChange={handleChange} name="buy_trailing_delta_offset"/>
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="calculation_mode">
            Calculation Mode
          </label>
          <select
              name="calculation_mode"
              value={botState.calculation_mode}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="PREDEFINED">PREDEFINED</option>
            <option value="ACTUAL_EXECUTION">ACTUAL_EXECUTION</option>
          </select>
          {errors.calculation_mode &&
              <p className="text-red-500 text-xs italic">{errors.calculation_mode}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profit_capitalization">
            Profit Capitalization
          </label>
          <input
              type="number"
              name="profit_capitalization"
              value={botState.profit_capitalization || ""}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.profit_capitalization &&
              <p className="text-red-500 text-xs italic">{errors.profit_capitalization}</p>}
        </div>


        {/* Add other fields similar to the CreateBot form */}
        <div className="mb-4 col-span-4">
          <h3 className="text-lg font-bold mb-2">Indicators</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="mb-4 col-span-2 sm:col-span-1">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="indicator">
                Indicator
              </label>
              <select
                  name="indicator"
                  value={newIndicator.indicator || ""}
                  onChange={handleIndicatorChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Select Indicator</option>
                <option value="rsi">RSI</option>
                <option value="cci">CCI</option>
                <option value="stochrsi">STOCHRSI</option>
                <option value="macd">MACD</option>
                <option value="price_change">PRICE CHANGE</option>
              </select>
            </div>

            {newIndicator.indicator !== "price_change" && (
                <>
                  <div className="mb-4 col-span-2 sm:col-span-1">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="interval">
                      Interval
                    </label>
                    <select
                        name="interval"
                        value={newIndicator.interval || ""}
                        onChange={handleIndicatorChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select interval</option>
                      <option value="1m">1m</option>
                      <option value="5m">5m</option>
                      <option value="15m">15m</option>
                      <option value="30m">30m</option>
                      <option value="1h">1h</option>
                      <option value="2h">2h</option>
                      <option value="3h">3h</option>
                      <option value="4h">4h</option>
                      <option value="6h">6h</option>
                      <option value="12h">12h</option>
                      <option value="1d">1d</option>
                    </select>
                  </div>
                  {newIndicator.indicator !== "macd" && newIndicator.indicator !== "stochrsi" && (
                      <div className="mb-4 col-span-2 sm:col-span-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="period">
                          Period
                        </label>
                        <select
                            name="period"
                            value={newIndicator.period || ""}
                            onChange={handleIndicatorChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="">Select period</option>
                          {newIndicator.indicator === "rsi" && (
                              <>
                                <option value="6">6</option>
                                <option value="14">14</option>
                                <option value="24">24</option>
                              </>
                          )}

                          {newIndicator.indicator === "cci" && (
                              <>
                                <option value="20">20</option>
                              </>
                          )}
                        </select>
                      </div>
                  )}
                </>
            )}

            {newIndicator.indicator === "price_change" && (
                <>
                  <div className="mb-4 col-span-2 sm:col-span-1">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="interval">
                      Hours
                    </label>
                    <select
                        name="interval"
                        value={newIndicator.interval || ""}
                        onChange={(e) => {
                          handleIndicatorChange(e);
                        }}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select Hours</option>
                      <option value="1">1</option>
                      <option value="3">3</option>
                      <option value="6">6</option>
                      <option value="12">12</option>
                      <option value="24">24</option>
                    </select>
                  </div>
                </>
            )}

            {(newIndicator.indicator === "rsi" || newIndicator.indicator === "cci" || newIndicator.indicator === "stochrsi" || newIndicator.indicator === "price_change") && (
                <>
                  <div className="mb-4 col-span-2 sm:col-span-1">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="value">
                      Value
                    </label>
                    <input
                        type="number"
                        name="value"
                        value={newIndicator.value || ""}
                        onChange={handleIndicatorChange}
                        min="0"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4 col-span-2 sm:col-span-1">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="more_than">
                      More Than
                    </label>
                    <Checkbox checked={newIndicator.more_than} onChange={handleIndicatorChange} name="more_than"/>
                  </div>
                </>
            )}

            {["macd", "stochrsi", "rsi"].includes(newIndicator.indicator) && (
                <>
                  <div className="mb-4 col-span-2 sm:col-span-1">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cross_type">
                      Cross Type
                    </label>
                    <select
                        name="cross_type"
                        value={newIndicator.cross_type || ""}
                        onChange={handleIndicatorChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">Select cross mode</option>
                      {newIndicator.indicator === "macd" && (
                          <>
                            <option value="long_plus">LONG PLUS</option>
                          </>
                      )}
                      <option value="long">LONG</option>
                    </select>
                  </div>
                  {["long_plus", "long"].includes(newIndicator.cross_type) && (
                      <div className="mb-4 col-span-2 sm:col-span-1">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cross_type_mode">
                          Cross Type Mode
                        </label>
                        <Checkbox checked={newIndicator.cross_type_mode} onChange={handleIndicatorChange}
                                  name="cross_type_mode"/>
                      </div>
                  )}
                </>
            )}

            {newIndicator.indicator === "stochrsi" && (
                <div className="mb-4 col-span-2 sm:col-span-1">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="period">
                    Parameter comparison
                  </label>
                  <select
                      name="parameter_comparison"
                      value={newIndicator.parameter_comparison || ""}
                      onChange={handleIndicatorChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select comparison</option>
                    <option value="K_LESS_THAN_D">K LESS D</option>
                    <option value="K_GREATER_THAN_D">K GREATER D</option>
                  </select>
                </div>
            )}

            <div className="mb-4 col-span-4">
              <button
                  type="button"
                  onClick={addIndicator}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Add Indicator
              </button>
            </div>
          </div>

          <div className="mb-4 col-span-4">
            {botState.indicators.map((indicator, index) => (
                <div key={index} className="border p-2 mb-2 relative">
                  <p>Indicator: {indicator.indicator}</p>
                  <p>Interval: {indicator.interval}</p>
                  <p>Value: {indicator.value}</p>
                  <p>Period: {indicator.period}</p>
                  <p>More Than: {indicator.more_than ? "Yes" : "No"}</p>
                  <p>Cross Type: {indicator.cross_type}</p>
                  <p>Cross Type Mode: {indicator.cross_type_mode ? "Yes" : "No"}</p>
                  {indicator.indicator === "stochrsi" && indicator.parameter_comparison !== null && (
                      <p>StochRSI comparison: {indicator.parameter_comparison}</p>
                  )}
                  <button
                      type="button"
                      onClick={() => removeIndicator(index)}
                      className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
            ))}
          </div>
        </div>

        <div className="col-span-2 flex items-center justify-between">
          <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
          >
            {isSubmitting ? 'Updating...' : 'Update Bot'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditBot;
