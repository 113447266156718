const API_BASE_URL = "https://api.addtradingbot.com/api/v1";
// const API_BASE_URL = "http://localhost:8001/api/v1";

const apiConfig = {
    getToken: `${API_BASE_URL}/users/token`,
    checkAuth: `${API_BASE_URL}/users/check`,
    fetchBots: `${API_BASE_URL}/bots/list`,
    createBot: `${API_BASE_URL}/bots/create`,
    editBot: `${API_BASE_URL}/bots/edit`,
    startBot: `${API_BASE_URL}/bots/start`,
    stopBot: `${API_BASE_URL}/bots/stop`,
    cyclePause: `${API_BASE_URL}/cycles/pause`,
    cycleActivate: `${API_BASE_URL}/cycles/activate`,
    editCycle: `${API_BASE_URL}/cycles/edit`,
    fetchStats: `${API_BASE_URL}/statistic/bots`,
    fetchVolatility: `${API_BASE_URL}/statistic/volatility`,
    getSymbols: `${API_BASE_URL}/statistic/symbols`,
};

export default apiConfig;