import React, { useMemo, useState } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import Card from "../../../../components/card";

const ComplexTable = ({ columnsData, tableData }) => {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData.data, [tableData.data]);
  const total_profit = tableData.total_profit;
    const total_profit_percentage = tableData.total_profit_percentage;
    const total_deposit = tableData.total_deposit;
    const total_cycles_completed = tableData.total_cycles_completed;
  const [openRows, setOpenRows] = useState({});

  const toggleRow = (rowId) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
              <thead>
              {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, index) => (
                          <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              key={index}
                              className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:border-navy-700"
                          >
                              <p className="text-xs tracking-wide text-gray-600">
                                  {column.render("Header")}
                              </p>
                          </th>
                      ))}
                  </tr>
              ))}
              </thead>
              <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                  prepareRow(row);
                  const isOpen = openRows[row.id];
                  return (
                      <React.Fragment key={index}>
                          <tr
                              {...row.getRowProps()}
                              onClick={() => toggleRow(row.id)}
                              className="cursor-pointer"
                          >
                              {row.cells.map((cell, index) => {
                                  let data = "";
                                  if (cell.column.Header === "Name") {
                                      data = (
                                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                                              {cell.value}
                                          </p>
                                      );
                                  } else if (
                                      cell.column.Header === "Profit" ||
                                      cell.column.Header === "Profit %" ||
                                      cell.column.Header === "Deposit" ||
                                      cell.column.Header === "Cycles Completed"
                                  ) {
                                      data = (
                                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                                              {cell.value}
                                          </p>
                                      );
                                  }
                                  return (
                                      <td
                                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                                          {...cell.getCellProps()}
                                          key={index}
                                      >
                                          {data}
                                      </td>
                                  );
                              })}
                          </tr>
                          {isOpen && (
                              <tr>
                                  <td colSpan={columns.length}>
                                      <div className="p-4">
                                          <SubTable data={row.original.base_assets}/>
                                      </div>
                                  </td>
                              </tr>
                          )}
                      </React.Fragment>
                  );
              })}
              </tbody>
              <tfoot>
              <tr>
                  <td className="font-bold">
                      Total
                  </td>
                  <td className="font-bold">
                      {total_profit}
                  </td>
                  <td className="font-bold">
                      {total_profit_percentage}
                  </td>
                  <td className="font-bold">
                      {total_deposit}
                  </td>
                  <td className="font-bold">
                      {total_cycles_completed}
                  </td>
              </tr>
              </tfoot>
          </table>
      </div>
    </Card>
  );
};

const SubTable = ({data}) => {
    const columns = useMemo(
        () => [
            {
                Header: "Base Asset",
                accessor: "base_asset",
            },
            {
                Header: "Profit",
                accessor: "profit",
            },
            {
                Header: "Profit %",
                accessor: "profit_percentage",
            },
            {
                Header: "Deposit",
                accessor: "deposit",
            },
            {
                Header: "Cycles Completed",
                accessor: "cycles_completed",
            },
            {
                Header: "Cycles Average Profit %",
        accessor: "average_cycles_profit",
      },
    ],
    []
  );

  const [openBaseAssetRows, setOpenBaseAssetRows] = useState({});

  const toggleBaseAssetRow = (rowId) => {
    setOpenBaseAssetRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <table {...getTableProps()} className="w-full">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps()}
                key={index}
                className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:border-navy-700"
              >
                <p className="text-xs tracking-wide text-gray-600">
                  {column.render("Header")}
                </p>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const isOpen = openBaseAssetRows[row.id];
          return (
            <React.Fragment key={index}>
              <tr
                {...row.getRowProps()}
                onClick={() => toggleBaseAssetRow(row.id)}
                className="cursor-pointer"
              >
                {row.cells.map((cell, index) => (
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                    {...cell.getCellProps()}
                    key={index}
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {cell.value}
                    </p>
                  </td>
                ))}
              </tr>
              {isOpen && (
                <tr>
                  <td colSpan={columns.length}>
                    <div className="p-4">
                      <CycleTable data={row.original.cycles} />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
};

const CycleTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Completed At",
        accessor: "completed_at",
        Cell: ({ value }) => {
          // Форматирование даты
          return formatDate(value);
        },
      },
      {
        Header: "Profit",
        accessor: "profit",
      },
      {
        Header: "Profit %",
        accessor: "profit_percentage",
      },
      {
        Header: "Cumulative Transacted",
        accessor: "cumulative_transacted",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // используем page вместо rows для пагинации
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // начальная страница и размер страницы
    },
    usePagination
  );

  return (
    <div>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  key={index}
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    {column.render("Header")}
                  </p>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => { // используем page вместо rows
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => (
                  <td
                    className="pt-[14px] pb-[18px] sm:text-[14px]"
                    {...cell.getCellProps()}
                    key={index}
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {cell.render("Cell")}
                    </p>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ComplexTable;