import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiConfig from "../../../apiConfig";
import AuthContext from "../../../layouts/auth/AuthContext";
import ComplexTable from "../tables/components/ComplexTable";
import { columnsData } from "../tables/variables/columnsData";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const StatisticsPage = () => {
  const { user, isAuthLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [botsData, setBotsData] = useState([]);
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { startDate: start, endDate: end, key: 'selection' };
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthLoading && !user) {
      navigate("/auth/sign-in");
    }
  }, [isAuthLoading, user, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const formatDate = (date, endOfDay = false) => {
          if (!date) return null;
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = endOfDay ? '23' : String(date.getHours()).padStart(2, '0');
          const minutes = endOfDay ? '59' : String(date.getMinutes()).padStart(2, '0');
          const seconds = endOfDay ? '59' : String(date.getSeconds()).padStart(2, '0');

          return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        };

        const dataToSend = {
          start_date: formatDate(dateRange.startDate),
          end_date: formatDate(dateRange.endDate, true), // endOfDay = true для конца дня
        };

        if (!dataToSend.start_date || !dataToSend.end_date) {
          setError(new Error("Both start date and end date must be selected"));
          return;
        }

        const token = localStorage.getItem("access_token");

        const response = await axios.post(apiConfig.fetchStats, dataToSend, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setBotsData(response.data.data);
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange]);

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4 dark:bg-navy-700 dark:shadow-none">
      <div className="flex justify-between items-center mb-4">
        <DateRange
          ranges={[dateRange]}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
        />
      </div>
      <ComplexTable columnsData={columnsData} tableData={botsData} />
    </div>
  );
};

export default StatisticsPage;