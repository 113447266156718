import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';  // Импортируем react-select
import apiConfig from "../../../apiConfig";
import AuthContext from "../../../layouts/auth/AuthContext";
import ColumnsTable from "../tables/components/ColumnsTable";
import { volatilityData } from "../tables/variables/columnsData";

const VolatilityPage = () => {
  const { user, isAuthLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [volData, setVolData] = useState([]);
  const [profit_percentage, setProfit_percentage] = useState(0.5);
  const [quote_asset, setQuote_asset] = useState('USDT');
  const [selectedAssets, setSelectedAssets] = useState([]); // Выбранные активы
  const [availableAssets, setAvailableAssets] = useState([]); // Доступные активы

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthLoading && !user) {
      navigate("/auth/sign-in");
    }
  }, [isAuthLoading, user, navigate]);

  // Fetch available base assets on component mount and when quote_asset changes
  useEffect(() => {
    const fetchBaseAssets = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const response = await axios.get(apiConfig.getSymbols, {
          params: { quote_asset },
          headers: { Authorization: `Bearer ${token}` },
        });
        // Преобразуем данные в формат, который поддерживается react-select
        const assetsOptions = response.data.map((asset) => ({
          value: asset,
          label: asset,
        }));
        setAvailableAssets(assetsOptions);
      } catch (error) {
        console.error("Ошибка при загрузке списка монет:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBaseAssets();
  }, [quote_asset]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("access_token");

      const dataToSend = {
        profit_percentage,
        quote_asset,
        base_assets: selectedAssets.map(asset => asset.value), // Отправляем выбранные активы
      };

      const response = await axios.post(apiConfig.fetchVolatility, dataToSend, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setVolData(response.data.data);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAssetsChange = (selectedOptions) => {
    setSelectedAssets(selectedOptions || []); // Обновляем выбранные активы
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4 dark:bg-navy-700 dark:shadow-none">
      <form onSubmit={handleSubmit} className="mb-4 grid grid-cols-4 gap-4">
        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profit_percentage">
            Volatility (%)
          </label>
          <input
            type="number"
            name="profit_percentage"
            value={profit_percentage}
            onChange={(e) => setProfit_percentage(e.target.value)}
            step={0.1}
            min={0.1}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quote_asset">
            Quote asset
          </label>
          <select
              name="currency"
              value={quote_asset}
              onChange={(e) => setQuote_asset(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
          >
            <option value="USDT">USDT</option>
            <option value="FDUSD">FDUSD</option>
            <option value="USDC">USDC</option>
            <option value="BTC">BTC</option>
            <option value="ETH">ETH</option>
            <option value="BNB">BNB</option>
            <option value="TRY">TRY</option>
            <option value="EUR">EUR</option>
            <option value="BRL">BRL</option>
          </select>
        </div>
        <div className="mb-4 col-span-2 sm:col-span-2">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="base_assets">
            Base Assets
          </label>
          <Select
            isMulti
            name="base_assets"
            options={availableAssets} // Опции для выбора активов
            className="basic-multi-select"
            classNamePrefix="select"
            value={selectedAssets} // Текущие выбранные активы
            onChange={handleAssetsChange} // Обработчик изменения выбора
            placeholder="Search and select assets..."
          />
        </div>
        <div className="col-span-2 flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            SUBMIT
          </button>
        </div>
      </form>

      {volData.length > 0 && (
        <ColumnsTable columnsData={volatilityData} tableData={volData} />
      )}
    </div>
  );
};

export default VolatilityPage;