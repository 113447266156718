import React, { useState } from "react";
import axios from "axios";
import Dropdown from "components/dropdown";
import { AiFillCaretRight, AiFillStop } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../../../apiConfig";

function BotMenu(props) {
  const { transparent, botData } = props; // Получаем данные бота через props
  const [open, setOpen] = useState(false);
  const [botStatus, setBotStatus] = useState(botData.status);
  const navigate = useNavigate();

  const handleToggleBotStatus = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const url = botStatus === 'ACTIVE' ? apiConfig.stopBot : apiConfig.startBot;
      await axios.post(url, { bot_id: botData.id }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Обновление статуса бота в UI после успешного запроса
      setBotStatus(prevStatus => (prevStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'));
    } catch (error) {
      console.error('Error toggling bot status', error);
    }
  };

  const handleEdit = () => {
    navigate("/admin/edit-bot", { state: { botData } }); // Передаем данные бота на страницу редактирования
  };

  return (
    <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          open={open}
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <BsThreeDots className="h-6 w-6" />
        </button>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out"}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p
            onClick={handleToggleBotStatus}
            className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
          >
            <span>
              {botStatus === 'ACTIVE' ? <AiFillStop /> : <AiFillCaretRight />}
            </span>
            {botStatus === 'ACTIVE' ? 'Stop' : 'Start'}
          </p>
          <p
            onClick={handleEdit}
            className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
          >
            <span>
              <AiFillEdit />
            </span>
            Edit
          </p>
        </div>
      }
    />
  );
}

export default BotMenu;
