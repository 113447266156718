import React, { useState } from "react";
import axios from "axios";
import Dropdown from "components/dropdown";
import { AiFillCaretRight, AiFillStop } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import apiConfig from "../../../../apiConfig";

function CycleMenu(props) {
  const { transparent, cycleData } = props;
  const [open, setOpen] = useState(false);
  const [cycleStatus, setCycleStatus] = useState(cycleData.status);
  const [loading, setLoading] = useState(false); // Добавляем состояние для отслеживания загрузки
  const cycleId = cycleData.id;
  const navigate = useNavigate();

  const handleToggleCycleStatus = async (cancelOrders = false) => {
    if (loading) return; // Не делаем ничего, если уже в процессе загрузки
    setLoading(true); // Устанавливаем состояние загрузки в true

    try {
      const token = localStorage.getItem('access_token');
      const isActiveOrFunds = cycleStatus === 'ACTIVE' || cycleStatus === 'FUNDS';
      const url = isActiveOrFunds ? apiConfig.cyclePause : apiConfig.cycleActivate;

      // Определяем данные для тела запроса
      const data = {
        cancel_orders: cancelOrders,
        cycle_id: cycleId
      };

      await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      // Обновляем статус после успешного запроса
      setCycleStatus(prevStatus => (prevStatus === 'ACTIVE' || prevStatus === 'FUNDS' ? 'INACTIVE' : 'ACTIVE'));
    } catch (error) {
      console.error('Error toggling cycle status', error);
    } finally {
      setLoading(false); // Сбрасываем состояние загрузки независимо от результата
    }
  };

  const handleEdit = () => {
    navigate("/admin/edit-cycle", { state: { cycleData } });
  };

  return (
    <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          open={open}
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <BsThreeDots className="h-6 w-6" />
        </button>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out"}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          {(cycleStatus === 'ACTIVE' || cycleStatus === 'FUNDS') && (
            <>
              <p
                onClick={() => handleToggleCycleStatus(false)}
                className={`hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <span><AiFillStop /></span>
                Pause
              </p>
              <p
                onClick={() => handleToggleCycleStatus(true)}
                className={`hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <span><AiFillStop /></span>
                Pause with Cancel Orders
              </p>
            </>
          )}
          {(cycleStatus !== 'ACTIVE' && cycleStatus !== 'FUNDS' && cycleStatus !== 'ERROR') && (
            <p
              onClick={() => handleToggleCycleStatus(false)}
              className={`hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <span><AiFillCaretRight /></span>
              Activate
            </p>
          )}
          <p
            onClick={handleEdit}
            className={`hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <span><AiFillEdit /></span>
            Edit
          </p>
        </div>
      }
    />
  );
}

export default CycleMenu;