import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import apiConfig from "../../apiConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [bots, setBots] = useState([]);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isBotsLoading, setIsBotsLoading] = useState(true);

  const checkAuth = async () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await axios.get(apiConfig.checkAuth, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data.data);
      } catch (error) {
        console.error('Invalid token', error);
        setUser(null);
        localStorage.removeItem('access_token');
      } finally {
        setIsAuthLoading(false);
      }
    } else {
      setIsAuthLoading(false);
    }
  };

  const fetchBots = async () => {
    if (bots.length === 0) {
      setIsBotsLoading(true);
    }

    const token = localStorage.getItem('access_token');
    if (token) {
      try {
        const response = await axios.get(apiConfig.fetchBots, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBots(response.data.data);
      } catch (error) {
        console.error('Error fetching bots', error);
      } finally {
        setIsBotsLoading(false);
      }
    } else {
      setIsBotsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, bots, fetchBots, checkAuth, isAuthLoading, isBotsLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;