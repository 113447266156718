import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import apiConfig from "../../../apiConfig";
import Checkbox from "../../../components/checkbox";

const EditCycle = () => {
  const location = useLocation();
  const { cycleData: initialCycleData } = location.state || {};
  const [cycleState, setCycleState] = useState({
    id: initialCycleData.id,
    deposit: initialCycleData.deposit,
    grid_length: initialCycleData.grid_length,
    first_order_offset: initialCycleData.first_order_offset,
    num_orders: initialCycleData.num_orders,
    partial_num_orders: initialCycleData.partial_num_orders,
    next_order_volume: initialCycleData.next_order_volume,
    profit_percentage: initialCycleData.profit_percentage,
    trailing_delta: initialCycleData.trailing_delta,
    trailing_delta_offset: initialCycleData.trailing_delta_offset,
    buy_trailing_delta: initialCycleData.buy_trailing_delta,
    buy_trailing_delta_offset: initialCycleData.buy_trailing_delta_offset,
    price_change_percentage: initialCycleData.price_change_percentage,
    log_coefficient: initialCycleData.log_coefficient,
    profit_capitalization: initialCycleData.profit_capitalization,
    calculation_mode: initialCycleData.calculation_mode,
    price: initialCycleData.price,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCycleState((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true);

        // Function to replace empty strings with null
    const replaceEmptyStringsWithNull = (obj) => {
        for (const key in obj) {
            if (obj[key] === '') {
                obj[key] = null;
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                replaceEmptyStringsWithNull(obj[key]);
            }
        }
    };

    // Replace empty strings with null
    replaceEmptyStringsWithNull(cycleState);

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(apiConfig.editCycle, cycleState, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Cycle updated successfully", response.data);
      navigate("/admin/default");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        console.error("Error updating cycle", error);
      }
    } finally {
        setIsSubmitting(false);
      }
  };

  return (
    <div className="container mx-auto p-4">
      <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4">
        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="deposit">
            Deposit
          </label>
          <input
              type="number"
              name="deposit"
              value={cycleState.deposit}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.deposit && <p className="text-red-500 text-xs italic">{errors.deposit}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="grid_length">
            Grid Length
          </label>
          <input
              type="number"
              name="grid_length"
              value={cycleState.grid_length}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.grid_length && <p className="text-red-500 text-xs italic">{errors.grid_length}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_order_offset">
            First Order Offset
          </label>
          <input
              type="number"
              name="first_order_offset"
              value={cycleState.first_order_offset}
              onChange={handleChange}
              step="0.01"
              min="0"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.first_order_offset && <p className="text-red-500 text-xs italic">{errors.first_order_offset}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="num_orders">
            Number of Orders
          </label>
          <input
              type="number"
              name="num_orders"
              value={cycleState.num_orders}
              onChange={handleChange}
              min="1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.num_orders && <p className="text-red-500 text-xs italic">{errors.num_orders}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="partial_num_orders">
            Partial Number of Orders
          </label>
          <input
              type="number"
              name="partial_num_orders"
              value={cycleState.partial_num_orders}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.partial_num_orders && <p className="text-red-500 text-xs italic">{errors.partial_num_orders}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="next_order_volume">
            Next Order Volume
          </label>
          <input
              type="number"
              name="next_order_volume"
              value={cycleState.next_order_volume}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.next_order_volume && <p className="text-red-500 text-xs italic">{errors.next_order_volume}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profit_percentage">
            Profit Percentage
          </label>
          <input
              type="number"
              name="profit_percentage"
              value={cycleState.profit_percentage}
              onChange={handleChange}
              step="0.01"
              min="0.1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.profit_percentage && <p className="text-red-500 text-xs italic">{errors.profit_percentage}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="trailing_delta">
            Profit Trailing Delta
          </label>
          <input
              type="number"
              name="trailing_delta"
              value={cycleState.trailing_delta}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.trailing_delta && <p className="text-red-500 text-xs italic">{errors.trailing_delta}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="trailing_delta_offset">
            Profit Trailing Delta Offset
          </label>
          <Checkbox checked={cycleState.trailing_delta_offset} onChange={handleChange} name="trailing_delta_offset"/>
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price_change_percentage">
            Price Change Percentage
          </label>
          <input
              type="number"
              name="price_change_percentage"
              value={cycleState.price_change_percentage}
              onChange={handleChange}
              step="0.01"
              min="0.1"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.price_change_percentage &&
              <p className="text-red-500 text-xs italic">{errors.price_change_percentage}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="log_coefficient">
            Log Coefficient
          </label>
          <input
              type="number"
              name="log_coefficient"
              value={cycleState.log_coefficient}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.log_coefficient && <p className="text-red-500 text-xs italic">{errors.log_coefficient}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buy_trailing_delta">
            Buy Trailing Delta
          </label>
          <input
              type="number"
              name="buy_trailing_delta"
              value={cycleState.buy_trailing_delta}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.buy_trailing_delta && <p className="text-red-500 text-xs italic">{errors.buy_trailing_delta}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buy_trailing_delta_offset">
            Buy Trailing Delta Offset
          </label>
          <Checkbox checked={cycleState.buy_trailing_delta_offset} onChange={handleChange} name="buy_trailing_delta_offset"/>
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="calculation_mode">
            Calculation Mode
          </label>
          <select
              name="calculation_mode"
              value={cycleState.calculation_mode}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="PREDEFINED">PREDEFINED</option>
            <option value="ACTUAL_EXECUTION">ACTUAL_EXECUTION</option>
          </select>
          {errors.calculation_mode && <p className="text-red-500 text-xs italic">{errors.calculation_mode}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profit_capitalization">
            Profit Capitalization
          </label>
          <input
              type="number"
              name="profit_capitalization"
              value={cycleState.profit_capitalization}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.profit_capitalization &&
              <p className="text-red-500 text-xs italic">{errors.profit_capitalization}</p>}
        </div>

        <div className="mb-4 col-span-2 sm:col-span-1">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
            Price
          </label>
          <input
              type="number"
              name="price"
              value={cycleState.price}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {errors.price && <p className="text-red-500 text-xs italic">{errors.price}</p>}
        </div>

        <div className="col-span-2 flex items-center justify-between">
          <button
              type="submit"
              disabled={isSubmitting}
              className={`bg-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
          >
            {isSubmitting ? 'Updating...' : 'Update Cycle'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCycle;
