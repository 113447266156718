import React from "react";

export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Profit',
      accessor: 'profit',
      Cell: ({ value }) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">{value}</p>
      ),
      sortType: (rowA, rowB) => {
        const a = parseFloat(rowA.values.profit);
        const b = parseFloat(rowB.values.profit);
        return a > b ? 1 : -1;
      },
    },
    {
      Header: 'Profit %',
      accessor: 'profit_percentage',
      Cell: ({ value }) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">{value}</p>
      ),
      sortType: (rowA, rowB) => {
        const a = parseFloat(rowA.values.profit_percentage);
        const b = parseFloat(rowB.values.profit_percentage);
        return a > b ? 1 : -1;
      },
    },
    {
      Header: 'Deposit',
      accessor: 'deposit',
      Cell: ({ value }) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">{value}</p>
      ),
      sortType: (rowA, rowB) => {
        const a = parseFloat(rowA.values.deposit);
        const b = parseFloat(rowB.values.deposit);
        return a > b ? 1 : -1;
      },
    },
    {
      Header: 'Cycles Completed',
      accessor: 'cycles_completed',
      Cell: ({ value }) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">{value}</p>
      ),
      sortType: (rowA, rowB) => {
        const a = parseFloat(rowA.values.cycles_completed);
        const b = parseFloat(rowB.values.cycles_completed);
        return a > b ? 1 : -1;
      },
    },
  ];

export const volatilityData = [
  {
    Header: "Position №",
    accessor: "position",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Quantity",
    accessor: "volatility",
  },
];
