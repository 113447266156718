import Card from "components/card";
import React, {useMemo} from "react";
import BotMenu from "./BotMenu";
import {MdCancel, MdCheckCircle, MdOutlineError} from "react-icons/md";
import Tooltip from "react-tooltip-lite";
import CycleMenu from "./CycleMenu";


const Bot = (props) => {
    const { transparent, botData } = props;
    const data = useMemo(() => botData, [botData]);

    const formatErrors = (errors) => {
        if (!errors || errors.length === 0) {
            return "No errors";
        }
        return (
            <div className="text-left bg-white rounded shadow-lg p-2">
                {errors.map((error, index) => (
                    <div key={index} className="mb-2">
                        <p><b>Message:</b> {error.message}</p>
                        <p><b>Cycle ID:</b> {error.cycle_id}</p>
                        <p><b>Order ID:</b> {error.order_id}</p>
                        <p><b>Created:</b> {new Date(error.created_at).toLocaleString()}</p>
                    </div>
                ))}
            </div>
        );
    };

    const formatSymbols = (symbols) => {
            if (!symbols || Object.keys(symbols).length === 0) {
                return "";
            }
            return Object.keys(symbols).map((symbol, index) => (
                <Tooltip
                    key={index}
                    content={formatSymbolSettings(symbols[symbol])}
                    direction="up"
                    tagName="span"
                    className="mr-1 cursor-pointer"
                >
                    <span>{symbol.toUpperCase()}</span>
                    {index < Object.keys(symbols).length - 1 && ", "}
                </Tooltip>
            ));
    };

    const formatSymbolSettings = (settings) => {
        return (
            <div className="text-left bg-white rounded shadow-lg w-full h-full">
                <p>First Order Offset: <b>{settings.first_order_offset}</b></p>
                <p>Grid Length: <b>{settings.grid_length}</b></p>
                <p>Log Coefficient: <b>{settings.log_coefficient}</b></p>
                <p>Next Order Volume: <b>{settings.next_order_volume}</b></p>
                <p>Number of Orders: <b>{settings.num_orders}</b></p>
                <p>Partial Number of Orders: <b>{settings.partial_num_orders}</b></p>
                <p>Price Change Percentage: <b>{settings.price_change_percentage}</b></p>
                <p>Profit Percentage: <b>{settings.profit_percentage}</b></p>
                <p>Upper Price: <b>{settings.upper_price_limit}</b></p>
                <p>Profit Trailing Delta: <b>{settings.trailing_delta}</b></p>
                <p>Profit Trailing Delta Offset: <b>{settings.trailing_delta_offset === true ? 'yes' : settings.trailing_delta_offset}</b></p>
                <p>Buy Trailing Delta: <b>{settings.buy_trailing_delta}</b></p>
                <p>Buy Trailing Delta Offset: <b>{settings.buy_trailing_delta_offset === true ? 'yes' : settings.buy_trailing_delta_offset}</b></p>
            </div>
        );
    };

    const formatIndicator = (indicator) => {
        const {indicator: type, interval, more_than, period, value, cross_type, cross_type_mode, parameter_comparison} = indicator;
        const moreThanSymbol = more_than ? ">" : "<";
        const crossType = cross_type ? ` | ${cross_type}` : "";
        const crossTypeMode = cross_type_mode ? ` | ${cross_type_mode}` : "";
        const getComparisonString = (parameterComparison) => {
            if (parameterComparison === "K_GREATER_THAN_D") {
                return "K > D";
            } else if (parameterComparison === "K_LESS_THAN_D") {
                return "K < D";
            } else {
                return "";
            }
        };
        const parameterComparison = getComparisonString(parameter_comparison);

        return `${type.toUpperCase()} (${interval} | ${period || ""} | ${moreThanSymbol} ${value || ""} | ${crossType}${crossTypeMode} | ${parameterComparison}) `;
    };

    return (
        <Card extra={"w-full h-full"}>
            <div key={data.id} className="ml-auto">
                <BotMenu botData={botData}/>
            </div>
            {/* Header */}
            <div className="mb-2 w-full">
                <h4 className="px-4 text-xl font-bold text-navy-700 dark:text-white">
                    {data.name}
                    
                </h4>
                <p className="px-4">
                    Free balance: {data.quote_asset_balance} 
                </p>
                <p className="px-4 text-base text-gray-600">
                    id: {data.id}
                </p>
            </div>

            <Card extra={"w-full h-full p-3 bg-none shadow-none"}>
                <div className="flex rounded-2xl bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:!shadow-none">
                    <div className="flex items-center mt-2 mb-2">
                        <div className="ml-4">
                            <p className="text-sm text-gray-600">
                                Type:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.type}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Max cycles:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.max_active_cycles}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Deposit:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.deposit}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Profit capitalization:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.profit_capitalization}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Sort mode:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.symbol_sort_type}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Calculation mode:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.calculation_mode}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Black symbols:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.black_symbols && data.black_symbols.length > 0 ? (
                                        data.black_symbols.map((symbol, index) => (
                                            <span key={index}>
                                {symbol.toUpperCase()}
                                                {index < data.black_symbols.length - 1 && ", "}
                            </span>
                                        ))
                                    ) : (
                                        ""
                                    )}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                White symbols:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.white_symbols && Object.keys(data.white_symbols).length > 0 ? (
                                        formatSymbols(data.white_symbols)
                                    ) : (
                                        "No white symbols"
                                    )}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                Indicators:
                                <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                    {data.indicators.map((indicator, index) => (
                                        <span key={index}>
                            {formatIndicator(indicator)}
                                            {index < data.indicators.length - 1 && ", "}
                        </span>
                                    ))}
                                </b>
                            </p>
                            <p className="text-sm text-gray-600">
                                <span className="flex items-center gap-2">
                                    <span className="text-sm text-gray-600">
                                        Status:
                                    </span>
                                    <Tooltip
                                        content={formatErrors(data.errors)}
                                        direction="up"
                                        tagName="span"
                                        className="cursor-pointer"
                                    >
                                        <span className={`rounded-full text-sm`}>
                                            {data.errors && data.errors.length > 0 ? (
                                                <MdCheckCircle className="text-yellow-500"/>
                                            ) : data.status === "ACTIVE" ? (
                                                <MdCheckCircle className="text-green-500"/>
                                            ) : data.status === "DEACTIVATED" ? (
                                                <MdCancel className="text-gray-500"/>
                                            ) : data.status === "FUNDS" ? (
                                                <MdOutlineError className="text-orange-500"/>
                                            ) : data.status === "ERROR" ? (
                                                <MdOutlineError className="text-red-500"/>
                                            ) : null}
                                        </span>
                                    </Tooltip>
                                    <span className="font-medium text-navy-700 dark:text-white">
                                        {data.status}
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
            <div className="w-full h-full mt-3 mb-3">
                <div className="grid grid-cols-2 gap-4 px-3">
                    {data.cycles.map((cycle, index) => (
                        <div key={index}
                             className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">

                            <div key={cycle.id} className="ml-auto">
                                <CycleMenu cycleData={cycle}/>
                            </div>

                            <div className="flex items-center">
                                <div className="ml-4">
                                    <p className="text-sm text-gray-600">
                                        Symbol:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.symbol}
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        Orders:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.executed_orders} / {cycle.total_orders}
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        Executed:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.executed_volume}
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        Required:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.required_volume}
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        Current:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.current_price}
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        Sell:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.sell_price} | {cycle.sell_diff_percentage}%
                                        </b>
                                    </p>
                                    
                                    <p className="text-sm text-gray-600">
                                        Buy:
                                        <b className="ml-1 font-medium text-navy-700 dark:text-white">
                                            {cycle.buy_price} | {cycle.buy_diff_percentage}%
                                        </b>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        <span className="flex items-center gap-2">
                                            <span className="text-sm text-gray-600">
                                                Status:
                                            </span>
                                            <Tooltip
                                                content={formatErrors(cycle.errors)}
                                                direction="up"
                                                tagName="span"
                                                className="cursor-pointer"
                                            >
                                                <span className={`rounded-full text-sm`}>
                                                    {cycle.errors && cycle.errors.length > 0 ? (
                                                        <MdCheckCircle className="text-yellow-500"/>
                                                    ) : cycle.status === "ACTIVE" ? (
                                                        <MdCheckCircle className="text-green-500"/>
                                                    ) : cycle.status === "PAUSE" ? (
                                                        <MdCancel className="text-gray-500"/>
                                                    ) : cycle.status === "FUNDS" ? (
                                                        <MdOutlineError className="text-orange-500"/>
                                                    ) : cycle.status === "ERROR" ? (
                                                        <MdOutlineError className="text-red-500"/>
                                                    ) : null}
                                                </span>
                                            </Tooltip>
                                            <span className="font-medium text-navy-700 dark:text-white">
                                                {cycle.status}
                                            </span>
                                        </span>
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        <span className="flex items-center gap-2">
                                            <span className="text-sm text-gray-600">
                                                Settings:
                                            </span>
                                            <span className={`rounded-full text-sm`}>
                                                {cycle.similar_settings === true ? (
                                                    <MdCheckCircle className="text-green-500"/>
                                                ) : cycle.similar_settings === false ? (
                                                    <MdCancel className="text-gray-500"/>
                                                ) : null}
                                            </span>

                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </Card>
    );
};

export default Bot;
