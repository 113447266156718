import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdLock,
  MdAddBox,
  MdBarChart,
  MdOutlineAddchart,
} from "react-icons/md";
import CreateBot from "./views/admin/create-bot";
import EditBot from "./views/admin/edit-bot";
import EditCycle from "./views/admin/edit-cycle";
import StatisticsPage from "./views/admin/stats";
import VolatilityPage from "./views/admin/volatility";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Statistics",
    layout: "/admin",
    icon: <MdBarChart className="w-6 h-6" />,
    path: "stats",
    component: <StatisticsPage />,
  },
  {
    name: "Volatility",
    layout: "/admin",
    icon: <MdOutlineAddchart className="w-6 h-6" />,
    path: "volatility",
    component: <VolatilityPage />,
  },
  {
    name: "Create Bot",
    layout: "/admin",
    path: "create-bot",
    icon: <MdAddBox className="h-6 w-6" />,
    component: <CreateBot />,
  },
  {
    name: "Edit Bot",
    layout: "/admin",
    path: "edit-bot",
    icon: <MdAddBox className="h-6 w-6" />,
    component: <EditBot />,
    hideInSidebar: true,
  },
  {
    name: "Edit Cycle",
    layout: "/admin",
    path: "edit-cycle",
    icon: <MdAddBox className="h-6 w-6" />,
    component: <EditCycle />,
    hideInSidebar: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    hideInSidebar: true,
  },

];
export default routes;
