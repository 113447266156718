import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Bot from "./components/Bot";
import AuthContext from "../../../layouts/auth/AuthContext";

const Dashboard = () => {
  const { user, bots, fetchBots, isAuthLoading, isBotsLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    if (!isAuthLoading) {
      if (!user) {
        navigate("/auth/sign-in");
      } else {
        const interval = setInterval(() => {
        setTimeElapsed(prevTime => {
          const newTime = prevTime + 1;
          if (newTime % 5 === 0 || newTime === 1) { // Обновление данных каждые 5 секунд
            fetchBots();
          }
          return newTime;
        });
      }, 1000); // Проверка каждую секунду

        // Очистка интервала при размонтировании компонента или изменении пользователя
        return () => clearInterval(interval);
      }
    }
  }, [isAuthLoading, user, fetchBots, navigate]);

  if (isBotsLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="loader inline-block w-16 h-16 border-8 border-t-8 border-blue-500 rounded-full animate-spin"></div>
          <p className="text-2xl font-bold text-gray-700 mt-4">Loading bots...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    navigate("/auth/sign-in");
  }

  return (
    <div className="w-full mt-3 flex flex-wrap gap-5">
      {bots.map((bot, index) => (
        <div key={index} className="w-full h-fit lg:w-2/5 mb-5">
          <Bot botData={bot} />
        </div>
      ))}
    </div>
  );
};

export default Dashboard;